import React from 'react'
import { InstagramEmbed, LinkedInEmbed } from 'react-social-media-embed';
import { FacebookEmbed } from 'react-social-media-embed';
import './SocialMedia.css'
import OnScrollAnimation from '../../OnScrollAnimation';
function SocialMedia() {
    return (
        <div className='socials-main-div'>
            <div className='sm-container clients-header-spacing'>
                <OnScrollAnimation title1="c1" title2="c2" hr1="c3" hr2="c4" heading="Facebook Feed" subheading="" />
             
                <div className="row sm-row  ">
                    <div className='col-md-6 col-xl-4 sm-mt'>
                        <FacebookEmbed url="https://www.facebook.com/permalink.php?story_fbid=pfbid0X2ibMT8bd1BeQUKMSVE1t28UsN37Z7aJKi3cpjoBjwZ8Yr7C5oy1CiQsURvoCeWDl&id=100093287444014" height={400} />
                    </div>

                    <div className="col-md-6 col-xl-4 sm-mt">
                        <FacebookEmbed url="https://www.facebook.com/permalink.php?story_fbid=pfbid02DXHJ14M43sLBKduvN3HZ7UmybxmiLYde83iVc6eveW7tBjcncCuXFAWvdKgE6nf8l&id=100093287444014" height={400} />
                    </div>

                    <div className='col-md-6 col-xl-4 sm-mt'>
                        <FacebookEmbed url="https://www.facebook.com/permalink.php?story_fbid=pfbid02DXHJ14M43sLBKduvN3HZ7UmybxmiLYde83iVc6eveW7tBjcncCuXFAWvdKgE6nf8l&id=100093287444014" height={400} />
                    </div>
                </div>
                <OnScrollAnimation title1="c1" title2="c2" hr1="c3" hr2="c4" heading="Instragam Feed" subheading="" />
                <div className="row sm-row mt-5 pb-5 sm-mt">
                    <div className="col-md-6 col-xl-4">
                        <InstagramEmbed url="https://www.instagram.com/p/DC55dlZKVhu/?utm_source=ig_embed&amp;utm_campaign=loading/" captioned height={400}/>
                    </div>

                    <div className="col-md-6 col-xl-4 sm-mt">
                        <InstagramEmbed url="https://www.instagram.com/p/DCqU0DxKdYk/?utm_source=ig_embed&amp;utm_campaign=loading" captioned height={400}/>
                    </div>
                    <div className="col-md-6 col-xl-4 sm-mt">
                        <InstagramEmbed url="https://www.instagram.com/p/DCgB60UKH0r/?utm_source=ig_embed&amp;utm_campaign=loading" captioned height={400}/>
                    </div>
                </div>
                <OnScrollAnimation title1="c1" title2="c2" hr1="c3" hr2="c4" heading="LinkedIn Feed" subheading="" />
             
                <div className="row sm-row mt-5 pb-5 sm-mt">
            <div className="col-md-6 col-xl-4 sm-mt">
                <LinkedInEmbed url="https://www.linkedin.com/embed/feed/update/urn:li:share:7267782688961351680" height={600} width={400} />
            </div>
            
            <div className="col-md-6 col-xl-4 sm-mt">
                <LinkedInEmbed url="https://www.linkedin.com/embed/feed/update/urn:li:share:7265592474079809536" height={600} width={400}  />
            </div>

            <div className="col-md-6 col-xl-4 sm-mt">
                <LinkedInEmbed url="https://www.linkedin.com/embed/feed/update/urn:li:share:7264143392689831936" height={600} width={400}  />
            </div>
        </div>
            </div>
        </div>
    )
}

export default SocialMedia
