import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import GoogleIcon from '@mui/icons-material/Instagram';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import { useDispatch } from 'react-redux';
import { addcontactus } from '../Services/store/contactusSlice';
import './ContactUs.css';
import Loader from '../../Loader';
function ContactUs() {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [errors, setErrors] = useState({});
    const [type, setType] = useState("");
   
    const handleChange = (e) => {
        const { name, value } = e.target;

       
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const validate = () => {
        let errors = {};
        if (!formData.name) {
            errors.name = "Name is required";
        }
        if (!formData.email) {
            errors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            errors.email = "Email address is invalid";
        }
        if (!formData.message) {
            errors.message = "Message is required";
        }
        return errors;
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validate();
        setLoading(true)
        if (Object.keys(validationErrors).length === 0) {
            console.log(JSON.stringify(formData));

            var jsonparams = {
                "name": formData.name,
                "email": formData.email,
                "message": formData.message,
                "department": type
            }
            dispatch(addcontactus(jsonparams)).then((data) => {
                console.log(data);
                setLoading(false)

            });
            setFormData({ name: '', email: '', message: '' });
            setErrors({});
        } else {
            setErrors(validationErrors);
        }
    };
    return (
        <>
 {loading ? <Loader loading={loading} />:<></>}
            <div className="page-content">

                <section>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2925.6941797132995!2d72.66282845556651!3d23.154029049499623!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e802d75955e53%3A0xb9d6b192f2a881bc!2sGujarat%20Energy%20Research%20and%20Management%20Institute%20(GERMI)!5e0!3m2!1sen!2sin!4v1706767611266!5m2!1sen!2sin"
                        width="600"
                        height="450"
                        className='contactus-map'
                        frameBorder="0"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        aria-hidden="false"
                        tabIndex="0"
                    />
                </section>
                <section className="contact-us-section">
                    <div className="container">
                        <div className="row contact-row">
                            <div className="col-xl-5 col-lg-12">
                                <div className="contact-section">
                                    <h2 className="contact-heading-title heading-fonts">Gujarat Energy Research <br /> & Management Institute</h2>
                                    <div>
                                        <h1 className="heading-title twenty-sixteen-fonts" type="maps">Address</h1>
                                        <p className='normal-text'>1st Floor, Energy Building,
                                            Pandit Deendayal Energy University,
                                            <br />Raisan Village, Gandhinagar - 382 007, Gujarat </p>
                                        <h2 className="heading-title twenty-sixteen-fonts">Phone</h2>
                                        <p className='normal-text'>Phone: 079 2327 5757<br />Fax:  079 2327 5380</p>
                                        <h1 className="heading-title twenty-sixteen-fonts" type="mail">Email</h1>
                                        <p className='normal-text'>information@germi.org</p>
                                    </div>
                                    <div className="col-md-4">

                                    </div>
                                    <div className="pbmit-footer-social-area">
                                        <ul className="pbmit-social-links">
                                            {/* <li className="pbmit-social-li pbmit-social-podcast">
												<Link to="https://soundcloud.com/sowbhagya-rao-771090612" target="_blank">
													<span><i><PodcastsIcon /></i></span>
												</Link>
											</li> &nbsp; */}
                                            <li className="pbmit-social-li pbmit-social-facebook">
                                                <Link to="https://www.facebook.com/profile.php?id=100093287444014" target="_blank">
                                                    <span><i> <FacebookIcon /></i></span>
                                                </Link>
                                            </li>&nbsp;
                                            <li className="pbmit-social-li pbmit-social-twitter">
                                                <Link to="https://twitter.com/germirnd?lang=en" target="_blank">
                                                    <span><i className="pbmit-base-icon-twitter-square"><TwitterIcon /></i></span>
                                                </Link>
                                            </li>&nbsp;
                                            <li className="pbmit-social-li pbmit-social-linkedin">
                                                <Link to="https://www.linkedin.com/in/gujarat-energy-research-and-management-institute-2162851a4/" target="_blank">
                                                    <span><i><LinkedInIcon /></i></span>
                                                </Link>
                                            </li>&nbsp;
                                            <li className="pbmit-social-li pbmit-social-youtube">
                                                <Link to="https://www.youtube.com/channel/UCIQvWKLApGAVyQ5RrImLqlQ" target="_blank">
                                                    <span><i><YouTubeIcon /></i></span>
                                                </Link>
                                            </li>&nbsp;
                                            <li className="pbmit-social-li pbmit-social-google">
                                                <Link to="https://www.instagram.com/germi_gujarat/" target="_blank">
                                                    <span><i><GoogleIcon /></i></span>
                                                </Link>
                                            </li>&nbsp;
                                            <li className="pbmit-social-li pbmit-social-blog">
                                                <Link to="https://germipower.wordpress.com/author/faradayoftoday/" target="_blank">
                                                    <span><i><NewspaperIcon /></i></span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* <div align="center">
                                        <ul className="pt-4">
                                            <li><Link to="https://www.facebook.com/GERMI.GRIIC/"><i><FacebookIcon /></i></Link></li>
                                            <li> &nbsp; &nbsp; &nbsp;
                                                <Link to="https://twitter.com/germirnd?lang=en"><i><TwitterIcon /></i></Link>
                                            </li>&nbsp;&nbsp;&nbsp;
                                            <li>
                                                <Link to="https://www.linkedin.com/school/gujarat-energy-research-and-management-institute/"><i><LinkedInIcon /></i></Link>
                                            </li>&nbsp;&nbsp;&nbsp;
                                        </ul>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-12">
                                <div className="contact-form contact-spacing">
                                    {/* <h2 className="heading-title">Send us a message</h2> */}
                                    <div className="heading-container">
                                        <h4 id="title2" className="title-animation-about-us heading-fonts">Send Us a Message</h4>
                                        {/* <h2 id="title2" className="title-animation-about-us">Current Available Positions at GERMI</h2> */}
                                        <div className="div-for-hr-animation">
                                            <hr id="hr1" className="animate-hr-tag-about-us" />
                                            <hr id="hr2" className="animate-hr-tag-about-us" />
                                        </div>
                                    </div>


                                    <form id="contact-form" action=""><br></br>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <input type="text" name="name" className="form-control" placeholder="Your Name *" value={formData.name}
                                                    onChange={handleChange} required style={{ paddingLeft: "10px" }} />
                                                {errors.name && <p className="error">{errors.name}</p>}
                                            </div>
                                            <div className="col-md-12">
                                                <input type="email" name="email" value={formData.email}
                                                    onChange={handleChange} className="form-control" placeholder="Your Email *" required style={{ paddingLeft: "10px" }} />

                                                {errors.email && <p className="error">{errors.email}</p>}
                                            </div>
                                            {/* <div className="col-md-12">
                                                <input type="text" name="subject" className="form-control" placeholder="Contact Number *" required style={{paddingLeft:"10px"}}/>
                                            </div> */}
                                            <div className="col-md-12">
                                                <select style={{marginBottom:30,height:50}} value={type} onChange={text => setType(text.target.value)} >
                                                    <option>General Query</option>
                                                    <option>New Technology Department</option>
                                                    <option>Renewables Department</option>
                                                    <option>Petroleum Department</option>
                                                    <option>Petroleum Research Department</option>
                                                    <option>Environment Department</option>
                                                    <option>HR, Admin & IT Team</option>
                                                </select>
                                            </div>
                                            <div className="col-md-12">
                                                <textarea name="message" value={formData.message}
                                                    onChange={handleChange} cols="40" rows="5" className="form-control" placeholder="Message *" style={{ paddingLeft: "10px" }} required></textarea>
                                                {errors.message && <p className="error">{errors.message}</p>}
                                            </div>

                                            <div className="text-in-give-green" id="contact-status"></div>

                                           
                                            <div className="col-md-12">
                                                <button className="pbmit-btn" onClick={handleSubmit}>
                                                    <i className="form-btn-loader fa fa-circle-o-notch fa-spin fa-fw margin-bottom d-none"></i>
                                                    <span className='twenty-sixteen-fonts'> Send Message </span>
                                                </button>
                                            </div>
                                            {/* <div className="col-md-12 col-lg-12 message-status"></div> */}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className='foter-sotack-lower'>
                        <div className="effect-container">
                            <div className="circle-effect"></div>
                            <div className="circle-effect"></div>
                            <div className="circle-effect"></div>
                        </div>
                    </div> */}
                </section>

            </div>
        </>
    )
}

export default ContactUs