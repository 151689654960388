
import React, { useEffect, useState } from "react";
// import FusePageCarded from "@fuse/core/FusePageCarded/FusePageCarded";
import { RingLoader } from 'react-spinners'
// import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import CircularProgress from '@mui/material/CircularProgress';
import 'dayjs/locale/de';
import {
    Button,
    FormControl,
    InputLabel,
    FormHelperText,
    FormControlLabel,
    Select,
    MenuItem,
    TextField,
    TableBody,
    Paper,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Table,
    RadioGroup,
    Radio,
    FormGroup,
    Checkbox,
    Box,
    Grid,
    Fab
} from "@mui/material";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
// import { showMessage } from "app/store/fuse/messageSlice";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import BreadCrumbs from '../BreadCrumbs/BreadCrumbs';
import BreadCrumbImage from '../../Images_1/CommonSection/Braedcrumbs/gallery.jpg';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import IosShareIcon from '@mui/icons-material/IosShare';

// import { IosShareIcon, RestartAltIcon, CloudUploadIcon } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
// import FuseUtils from "@fuse/utils/FuseUtils";
import { EditorState } from "draft-js";
// import RichTextEditor from "app/shared-components/RichTextEditor";
import { lighten } from "@mui/system";
import WYSIWYGEditor from "../shared-components/WYSIWYGEditor";
// import { addCurrentOpening } from "./store/addCurrentOpeningSlice";
import { useNavigate, useLocation } from "react-router-dom";
import "./AddApplicationForm.css"
import { addapplication } from "./store/AddApplication";
import dayjs from "dayjs";
import { blue } from '@mui/material/colors';
import moment from "moment";
import { Remove } from "@mui/icons-material";
import SaveIcon from "@mui/icons-material/Save";
const AddApplicationForm = (props) => {
    const [toggle, setToggle] = useState(false);
    const handleToggleChange = () => {
        setToggle(!toggle);
    };
    const [bankcounter, setBankCounter] = useState(0);
   
    const [disabledates, setdisableddates] = useState([]);
    const [DateRangedata, setDateRangeData] = useState([]);
    const [selectedDays, setSelectedDays] = useState([]);
    const [selectedToggleOff, setSelectedToggleOff] = useState('off');
    const [enddate, setenddate] = useState("");
    const [startdate, setstartdate] = useState("");
    const [togglebankSubmit, setTogglebankSubmit] = useState(true);
    const [countercars, setCountercars] = useState(0);

    const [input1, setInput1] = useState("");
    const [input1Err, setInput1Err] = useState("");
    const [input2, setInput2] = useState("");
    const [input2Err, setInput2Err] = useState("");
    const [input5, setInput5] = useState("");
    const [input5Err, setInput5Err] = useState("");
    const [input6, setInput6] = useState("");
    const [input6Err, setInput6Err] = useState("");
    const [input3, setInput3] = useState(0);
    const [input3Err, setInput3Err] = useState("");
    const [input4, setInput4] = useState(0);
    const [input4Err, setInput4Err] = useState("");
    const [isEditItem, setEditItem] = useState(null);

    



    const dispatch = useDispatch();
    const navigate = useNavigate();
    const buttonSx = {
        ...({
            bgcolor: blue[700],
            '&:hover': {
                bgcolor: blue[800],
            },
        }),
    };

    const location = useLocation();
    const openingData = location.state;

    const [loadingState, setLoadingState] = useState(false);

    const [clerification, setClerification] = useState(false);

    const defaultValues = {
        positionName: openingData.positionname,
        mobileNo: "",
        confirmMobileNo: "",
        firstName: "",
        middleName: "",
        lastName: "",
        emailId: "",
        confirmEmailId: "",
        address: "",
        currentCity: "",
        currentState: "",
        nativeState: "",
        dateOfBirth: undefined,
        dobProofFile: "",
        currentDesignation: "",
        currentOrganization: "",
        experince: "",
        netToHandSalary: undefined,
        grossSalary: undefined,
        salarySlipFile: "",
        ssCompletedYear: undefined,
        sscBoradUniversity: "",
        sscSubjects: "",
        sscPercentage: undefined,
        hscCompletedYear: undefined,
        hscBoradUniversity: "",
        hscSubjects: "",
        hscPercentage: undefined,
        diplomaCompletedYear: undefined,
        diplomaBoradUniversity: "",
        diplomaSubjects: "",
        diplomaPercentage: undefined,
        graduationCompletedYear: undefined,
        graduationBoradUniversity: "",
        graduationSubjects: "",
        graduationPercentage: undefined,
        postGraduationCompletedYear: undefined,
        postGraduationBoradUniversity: "",
        postGraduationSubjects: "",
        postGraduationPercentage: undefined,
        pgDiplomaCompletedYear: undefined,
        pgDiplomaBoradUniversity: "",
        pgDiplomaSubjects: "",
        pgDiplomaPercentage: undefined,
        mbaCompletedYear: undefined,
        mbaBoradUniversity: "",
        mbaSubjects: "",
        mbaPercentage: undefined,
        doctoralCompletedYear: undefined,
        doctoralBoradUniversity: "",
        doctoralSubjects: "",
        doctoralPercentage: undefined,
        // otherCompletedYear: undefined,
        // otherBoradUniversity: "",
        // otherSubjects: "",
        // otherPercentage: undefined,
        firstOrganizationName: "",
        firstDesignation: "",
        firstDateOfJoining: undefined,
        firstDateOfRelieving: undefined,
        firstServiceLength: undefined,
        firstSalaryPerMonth: undefined,
        firstMainJobProfile: "",
        firstWorkExpCerti: "",
        secondOrganizationName: "",
        secondDesignation: "",
        secondDateOfJoining: undefined,
        secondDateOfRelieving: undefined,
        secondServiceLength: undefined,
        secondSalaryPerMonth: undefined,
        secondMainJobProfile: "",
        secondWorkExpCerti: "",
        thirdOrganizationName: "",
        thirdDesignation: "",
        thirdDateOfJoining: undefined,
        thirdDateOfRelieving: undefined,
        thirdServiceLength: undefined,
        thirdSalaryPerMonth: undefined,
        thirdMainJobProfile: "",
        thirdWorkExpCerti: "",
        minimumnoticePeriod: undefined,
        readyToRelocate: true,
        cvFile: "",
    };
    const validationSchema = Yup.object().shape({
        positionName: Yup.string().required("Position Name is Required"),
        mobileNo: Yup.string().matches(/^[0-9]{10}$/, 'Phone number is not valid'),
        confirmMobileNo: Yup.string().oneOf([Yup.ref('mobileNo'), null], 'Phone number must match'),
        firstName: Yup.string().required("Firstname is required"),
        middleName: Yup.string().required("Middlename is required"),
        lastName: Yup.string().required("Lastname is required"),
        emailId: Yup.string().matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Email is not valid'),
        confirmEmailId: Yup.string().oneOf([Yup.ref('emailId'), null], 'Email must match'),
        address: Yup.string().required("Address is required"),
        currentCity: Yup.string().required("Current city is required"),
        currentState: Yup.string().required("Current state is required"),
        nativeState: Yup.string().required("Native state is required"),
        dateOfBirth: Yup.date().required("Date of Birth is required"),
        dobProofFile: Yup.string().required("Proof for birthdate is required"),
        experince: Yup.string().required("Current experince is required")
    });

    const { handleSubmit, setValue, control, reset, watch, formState } = useForm({
        defaultValues,
        mode: "onChange",
        resolver: yupResolver(validationSchema),
    });

    const { isValid, dirtyFields, errors } = formState;


    const [selectedDobFile, setSelectedDobFile] = React.useState(null);
    const [selectedSalarySlipFile, setSelectedSalarySlipFile] = React.useState(null);
    const [selectedCVFile, setSelectedCVFile] = React.useState(null);

    const handleFileChangeDob = (event) => {
        setSelectedDobFile(event.target.files[0]);
        setValue("dobProofFile", event.target.files[0].name);
    }
    const handleFileChangeSalarySlip = (event) => {
        setSelectedSalarySlipFile(event.target.files[0]);
        setValue("salarySlipFile", event.target.files[0].name);
    }
    const handleFileChangeCV = (event) => {
        setSelectedCVFile(event.target.files[0]);
        setValue("cvFile", event.target.files[0].name);
    }



    // Here is On Submit Function for Button

    const onSubmit = (data) => {
        setLoadingState(true);
        var today = new Date();
        var birthDate = new Date(data.dateOfBirth);
        var age_now = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();

        console.log(Number(data.experince.split(" ")[0])+"--------"+Number(openingData.minexp))
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age_now--;
        }
        if (Number(data.experince.split(" ")[0]) < Number(openingData.minexp)) {
            alert(`Minimun experience required is ${openingData.minexp} years`);
            return;
        }
        if (age_now < Number(openingData.minage)) {
            alert(`Minimun age required is ${Number(openingData.minage)} years`);
            return;
        }
        if (age_now > Number(openingData.maxage)) {
            alert(`Maximum age required is ${openingData.maxage} years`);
            return;
        }
        const formData = new FormData();
        formData.append("openingid", openingData.id);
        formData.append("positionname", data.positionName);
        formData.append("mobileno", data.mobileNo);
        formData.append("firstname", data.firstName);
        formData.append("middlename", data.middleName);
        formData.append("lastname", data.lastName);
        formData.append("emailid", data.emailId);
        formData.append("address", data.address);
        formData.append("city", data.currentCity);
        formData.append("currentstate", data.currentState);
        formData.append("nativestate", data.nativeState);
        const dateOfBirth = dayjs(data.dateOfBirth)
        formData.append("birthdate", `${dateOfBirth.$y}-${(dateOfBirth.$M + 1).toString().padStart(2, '0')}-${dateOfBirth.$D.toString().padStart(2, '0')}`);
        formData.append("currentdesignation", data.currentDesignation);
        formData.append("currentorgaization", data.currentOrganization);
        formData.append("experience", data.experince);
        formData.append("nettohandsalary", data.netToHandSalary);
        formData.append("grosssalary", data.grossSalary);
        formData.append("noticeperiod", data.minimumnoticePeriod);
        formData.append("relocatelocation", data.readyToRelocate);
        if (data.hscCompletedYear) {
            const hscCompletedYear = dayjs(data.hscCompletedYear);
            formData.append("hsccompyear", `${hscCompletedYear.$y}-${(hscCompletedYear.$M + 1).toString().padStart(2, '0')}-${hscCompletedYear.$D.toString().padStart(2, '0')}`);
        }
        formData.append("hscboarduni", data.hscBoradUniversity);
        formData.append("hscsubjspecial", data.hscSubjects);
        formData.append("hscpercentage", data.hscPercentage);
        if (data.diplomaCompletedYear) {
            const diplomaCompletedYear = dayjs(data.diplomaCompletedYear);
            formData.append("dipyear", `${diplomaCompletedYear.$y}-${(diplomaCompletedYear.$M + 1).toString().padStart(2, '0')}-${diplomaCompletedYear.$D.toString().padStart(2, '0')}`);
        }
        formData.append("dipboarduni", data.diplomaBoradUniversity);
        formData.append("dipsubjspecial", data.diplomaSubjects);
        formData.append("dippercentage", data.diplomaPercentage);
        if (data.graduationCompletedYear) {
            const graduationCompletedYear = dayjs(data.graduationCompletedYear);
            formData.append("gradustionyear", `${graduationCompletedYear.$y}-${(graduationCompletedYear.$M + 1).toString().padStart(2, '0')}-${graduationCompletedYear.$D.toString().padStart(2, '0')}`);
        }
        formData.append("gradustionboarduni", data.graduationBoradUniversity);
        formData.append("gradustionsubjspecial", data.graduationSubjects);
        formData.append("gradustionpercentage", data.graduationPercentage);
        if (data.postGraduationCompletedYear) {
            const postGraduationCompletedYear = dayjs(data.postGraduationCompletedYear);
            formData.append("pgyear", `${postGraduationCompletedYear.$y}-${(postGraduationCompletedYear.$M + 1).toString().padStart(2, '0')}-${postGraduationCompletedYear.$D.toString().padStart(2, '0')}`);
        }
        formData.append("pgboarduni", data.postGraduationBoradUniversity);
        formData.append("pgsubjspecial", data.postGraduationSubjects);
        formData.append("pgpercentage", data.postGraduationPercentage);
        if (data.pgDiplomaCompletedYear) {
            const pgDiplomaCompletedYear = dayjs(data.pgDiplomaCompletedYear);
            formData.append("pgdipyear", `${pgDiplomaCompletedYear.$y}-${(pgDiplomaCompletedYear.$M + 1).toString().padStart(2, '0')}-${pgDiplomaCompletedYear.$D.toString().padStart(2, '0')}`);
        }
        formData.append("pgdipboarduni", data.pgDiplomaBoradUniversity);
        formData.append("pgdipsubjspecial", data.pgDiplomaSubjects);
        formData.append("pgdippercentage", data.pgDiplomaPercentage);
        if (data.mbaCompletedYear) {
            const mbaCompletedYear = dayjs(data.mbaCompletedYear);
            formData.append("mbayear", `${mbaCompletedYear.$y}-${(mbaCompletedYear.$M + 1).toString().padStart(2, '0')}-${mbaCompletedYear.$D.toString().padStart(2, '0')}`);
        }
        formData.append("mbaboarduni", data.mbaBoradUniversity);
        formData.append("mbasubjspecial", data.mbaSubjects);
        formData.append("mbapercentage", data.mbaPercentage);
        if (data.doctoralCompletedYear) {
            const doctoralCompletedYear = dayjs(data.doctoralCompletedYear);
            formData.append("docyear", `${doctoralCompletedYear.$y}-${(doctoralCompletedYear.$M + 1).toString().padStart(2, '0')}-${doctoralCompletedYear.$D.toString().padStart(2, '0')}`);
        }
        formData.append("docboarduni", data.doctoralBoradUniversity);
        formData.append("docsubjspecial", data.doctoralSubjects);
        formData.append("docpercentage", data.doctoralPercentage);
        formData.append("finstname", data.firstOrganizationName);
        formData.append("fdesignation", data.firstDesignation);
        if (data.firstDateOfJoining) {
            const firstDateOfJoining = dayjs(data.firstDateOfJoining);
            formData.append("fdateofjoin", `${firstDateOfJoining.$y}-${(firstDateOfJoining.$M + 1).toString().padStart(2, '0')}-${firstDateOfJoining.$D.toString().padStart(2, '0')}`);
        }
        if (data.firstDateOfRelieving) {
            const firstDateOfRelieving = dayjs(data.firstDateOfRelieving);
            formData.append("fdateofrel", `${firstDateOfRelieving.$y}-${(firstDateOfRelieving.$M + 1).toString().padStart(2, '0')}-${firstDateOfRelieving.$D.toString().padStart(2, '0')}`);
        }
        formData.append("flengthofervice", data.firstServiceLength);
        formData.append("fsalarypermonth", data.firstSalaryPerMonth);
        formData.append("fmainjobprofile", data.firstMainJobProfile);
        formData.append("fworkexp", data.firstWorkExpCerti);
        formData.append("sinstname", data.secondOrganizationName);
        formData.append("sdesignation", data.secondDesignation);
        if (data.secondDateOfJoining) {
            const secondDateOfJoining = dayjs(data.secondDateOfJoining);
            formData.append("sdateofjoin", `${secondDateOfJoining.$y}-${(secondDateOfJoining.$M + 1).toString().padStart(2, '0')}-${secondDateOfJoining.$D.toString().padStart(2, '0')}`);
        }
        if (data.secondDateOfRelieving) {
            const secondDateOfRelieving = dayjs(data.secondDateOfRelieving);
            formData.append("sdateofrel", `${secondDateOfRelieving.$y}-${(secondDateOfRelieving.$M + 1).toString().padStart(2, '0')}-${secondDateOfRelieving.$D.toString().padStart(2, '0')}`);
        }
        formData.append("slengthofervice", data.secondServiceLength);
        formData.append("ssalarypermonth", data.secondSalaryPerMonth);
        formData.append("smainjobprofile", data.thirdMainJobProfile);
        formData.append("sworkexp", data.secondWorkExpCerti);
        formData.append("tinstname", data.thirdOrganizationName);
        formData.append("tdesignation", data.thirdDesignation);
        if (data.thirdDateOfJoining) {
            const thirdDateOfJoining = dayjs(data.thirdDateOfJoining);
            formData.append("tdateofjoin", `${thirdDateOfJoining.$y}-${(thirdDateOfJoining.$M + 1).toString().padStart(2, '0')}-${thirdDateOfJoining.$D.toString().padStart(2, '0')}`);
        }
        if (data.thirdDateOfRelieving) {
            const thirdDateOfRelieving = dayjs(data.thirdDateOfRelieving);
            formData.append("tdateofrel", `${thirdDateOfRelieving.$y}-${(thirdDateOfRelieving.$M + 1).toString().padStart(2, '0')}-${thirdDateOfRelieving.$D.toString().padStart(2, '0')}`);
        }
        formData.append("tlengthofervice", data.thirdServiceLength);
        formData.append("tsalarypermonth", data.thirdSalaryPerMonth);
        formData.append("tmainjobprofile", data.thirdMainJobProfile);
        formData.append("tworkexp", data.thirdWorkExpCerti);
        formData.append("proofofbirth", selectedDobFile)
        formData.append("latetsalryslip", selectedSalarySlipFile)
        formData.append("cv", selectedCVFile)
        dispatch(addapplication(formData)).then((data) => { navigate("/careers") });
    };

    const handleClearInputs = (e) => {
        e.preventDefault();
        reset();
    };

    const [fields, setFields] = useState([{ nameOfOrg: '', designation: '', joiningDate: '', relievingDate: '', serviceLength: '', monthlySalary: '', jobProfile: '', experinceCertificate: '' }]);

    const handleChange = (index, event) => {
        const values = [...fields];
        values[index][event.target.name] = event.target.value;
        setFields(values);
    }

    const handleAdd = () => {
        setFields([...fields, { nameOfOrg: '', designation: '', joiningDate: '', relievingDate: '', serviceLength: '', monthlySalary: '', jobProfile: '', experinceCertificate: '' }]);
    };

    const handleRemove = (index) => {
        const values = [...fields];
        values.splice(index, 1);
        setFields(values);
    };
    const handleClickAddBank = (e) => {

        // console.log(DateRangedata.length+1+"---"+input1+"--"+input2+"--"+input3+"--"+input4+"--"+input5+"--"+input6+"--"+startdate+"--"+enddate)
        setBankCounter(bankcounter+1);
        setDateRangeData(
            ...DateRangedata,{
                  id: DateRangedata.length+1,
                  Name: input1,
                  Designation: input2,
                  Joining: startdate,
                  Relieving: enddate,
                  Length: input3,
                  Salary:input4,
                  Jobprofile:input5,
                  WECA:input6
            });


            // console.log(DateRangedata);
        
          
          setTogglebankSubmit(true);
          setInput1("");
          setInput2("");
          setstartdate("");
          setenddate("");
          setInput3("");
          setInput4("");
          setInput5("");
          setInput6("");
      };
      const handleClickRemoveBank = (index) => {
        const updateItems = DateRangedata.filter((elem, ind) => {
          return ind !== index;
        });
        setDateRangeData(updateItems);
        setTogglebankSubmit(true);
        setstartdate("");
        setenddate("");
        setCountercars(0);
        setBankCounter(bankcounter - 1);
    
        //console.log(index)
      };
    return (
        <div>
            <BreadCrumbs parent="Home" child="Job Application" parentPath="/" imgPath={BreadCrumbImage} />
            <div className="m-40" style={{ display: "flex", alignContent: "center", justifyContent: "center", marginBottom: "50px" }}>
                <form style={{ width: "85%", alignSelf: "center" }}>
                    <div >
                        <div className="mt-5 d-flex align-items-center mb-2 inputFlex">
                            <label className="lightText">Position Applied For</label>
                            {/* <Controller
                                name="positionName"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        className="w-full"
                                        error={!!errors.positionName}
                                        required
                                        disabled
                                        helperText={errors?.positionName?.message}
                                        label="Position Name"
                                        // autoFocus
                                        id="positionName"
                                        variant="outlined"
                                        style={{ width: "30%" }}
                                        sx={{
                                            fontSize: "10px"
                                        }}
                                    />
                                )}
                            /> */}
                            <Controller
                                name="positionName"
                                control={control}
                                render={({ field }) => (
                                    <FormControl
                                        variant="outlined"
                                        error={!!errors.positionName}
                                        style={{ width: "30%" }}
                                    >

                                        <InputLabel id="demo-simple-select-label">Position Name</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            {...field}
                                            className="mb-3"
                                            label="Position Applied For:"
                                            // autoFocus
                                            id="positionName"
                                            required
                                        >
                                            {/* <MenuItem value="">Position Applied</MenuItem> */}
                                            {
                                                [
                                                    {
                                                        value: true,
                                                        label: 'Assistant Manager',
                                                    },
                                                    {
                                                        value: false,
                                                        label: 'General Manager',
                                                    }
                                                ].map((i) => (<MenuItem value={i.value}>{i.label}</MenuItem>))
                                            }
                                        </Select>
                                        <FormHelperText>
                                            {errors?.PositionType?.message}
                                        </FormHelperText>
                                    </FormControl>
                                )}
                            />
                        </div>

                        <div className='text-salary form2-pt-10'>Full Name:</div>
                        <div className="d-flex flex-column mb-3">
                            <div className="mb-3" style={{ display: 'flex', gap: '20px' }}>
                                <div className="inputFlex" style={{ width: '100%' }}>
                                    <Controller
                                        name="firstName"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                className="w-full"
                                                error={!!errors.firstName}
                                                required
                                                helperText={errors?.firstName?.message}
                                                label="First Name"
                                                // autoFocus
                                                id="firstName"
                                                variant="outlined"
                                                style={{ width: "100%" }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="inputFlex" style={{ width: '100%' }}>
                                    <Controller
                                        name="middleName"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                className="w-full"
                                                error={!!errors.middleName}
                                                required
                                                helperText={errors?.middleName?.message}
                                                label="Middle Name"
                                                // autoFocus
                                                id="middleName"
                                                variant="outlined"
                                                style={{ width: "100%" }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="inputFlex" style={{ width: '100%' }}>
                                    <Controller
                                        name="lastName"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                className="w-full"
                                                error={!!errors.lastName}
                                                required
                                                helperText={errors?.lastName?.message}
                                                label="Surname"
                                                // autoFocus
                                                id="lastName"
                                                variant="outlined"
                                                style={{ width: "100%" }}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="d-flex" style={{ gap: '20px' }}>
                            <div className="d-flex w-full align-items-center mb-2 inputFlex">
                                <Controller
                                    name="mobileNo"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            className="mb-3"
                                            error={!!errors.mobileNo}
                                            required
                                            helperText={errors?.mobileNo?.message}
                                            label="Mobile No."
                                            // autoFocus
                                            id="mobileNo"
                                            variant="outlined"
                                            style={{ width: "100%" }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="d-flex w-full align-items-center mb-2 inputFlex">
                                <Controller
                                    name="confirmMobileNo"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            className="mb-3"
                                            error={!!errors.confirmMobileNo}
                                            required
                                            helperText={errors?.confirmMobileNo?.message}
                                            label="Confirm Mobile No."
                                            // autoFocus
                                            id="confirmMobileNo"
                                            variant="outlined"
                                            style={{ width: "100%" }}
                                        />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="d-flex" style={{ gap: '20px' }}>
                            <div className="d-flex w-full align-items-center mb-2 inputFlex">
                                <Controller
                                    name="emailId"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            className="mb-3"
                                            error={!!errors.emailId}
                                            required
                                            helperText={errors?.emailId?.message}
                                            label="Email"
                                            // autoFocus
                                            id="emailId"
                                            variant="outlined"
                                            style={{ width: "100%" }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="d-flex w-full align-items-center mb-2 inputFlex">
                                <Controller
                                    name="confirmEmailId"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            className="mb-3"
                                            error={!!errors.confirmEmailId}
                                            required
                                            helperText={errors?.confirmEmailId?.message}
                                            label="Confirm Email ID"
                                            // autoFocus
                                            id="confirmEmailId"
                                            variant="outlined"
                                            style={{ width: "100%" }}
                                        />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="d-flex" style={{ gap: '20px' }}>
                            <div className="w-full d-flex align-items-center mb-2 inputFlex">
                                <Controller
                                    name="address"
                                    control={control}
                                    render={({ field }) => (
                                        <textarea
                                            {...field}
                                            className="mb-3"
                                            error={!!errors.address}
                                            required
                                            helperText={errors?.address?.message}
                                            label="Correspondence Address"
                                            // placeholder="Address"
                                            rows={4}
                                            // autoFocus
                                            id="address"
                                            variant="outlined"
                                            style={{ width: "100%" }}
                                        />
                                    )}
                                />
                            </div>

                            <div className="w-full d-flex align-items-center mb-2 inputFlex">
                                <Controller
                                    name="currentPincode"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            className="mb-3"
                                            error={!!errors.currentPincode}
                                            required
                                            helperText={errors?.currentPincode?.message}
                                            label="Current Pincode"
                                            // autoFocus
                                            id="currentPincode"
                                            variant="outlined"
                                            style={{ width: "100%" }}
                                        />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="d-flex" style={{ gap: '20px' }}>
                            <div className="d-flex w-full align-items-center mb-2 inputFlex">
                                <Controller
                                    name="currentCity"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            className="mb-3"
                                            error={!!errors.currentCity}
                                            required
                                            helperText={errors?.currentCity?.message}
                                            label="Current City"
                                            // autoFocus
                                            id="currentCity"
                                            variant="outlined"
                                            style={{ width: "100%" }}
                                        />
                                    )}
                                />
                            </div>

                            <div className="d-flex w-full align-items-center mb-2 inputFlex">
                                <Controller
                                    name="currentState"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            className="mb-3"
                                            error={!!errors.currentState}
                                            required
                                            helperText={errors?.currentState?.message}
                                            label="Current State"
                                            // autoFocus
                                            id="currentState"
                                            variant="outlined"
                                            style={{ width: "100%" }}
                                        />
                                    )}
                                />
                            </div>

                            <div className="d-flex w-full align-items-center mb-2 inputFlex">
                                <Controller
                                    name="nativeState"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            className="mb-3"
                                            error={!!errors.nativeState}
                                            required
                                            helperText={errors?.nativeState?.message}
                                            label="Native State"
                                            // autoFocus
                                            id="nativeState"
                                            variant="outlined"
                                            style={{ width: "100%" }}
                                        />
                                    )}
                                />
                            </div>
                        </div>

                        <div className='text-salary form2-pt-10'>Employment Details:</div>
                        <div className="d-flex" style={{ gap: '20px' }}>
                            <div className="d-flex w-full align-items-center mb-2 inputFlex">
                                <Controller
                                    name="currentDesignation"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            className="mb-3"
                                            error={!!errors.currentDesignation}
                                            required
                                            helperText={errors?.currentDesignation?.message}
                                            label="Current Designation"
                                            // autoFocus
                                            id="currentDesignation"
                                            variant="outlined"
                                            style={{ width: "100%" }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="d-flex w-full align-items-center mb-2 inputFlex">
                                <Controller
                                    name="currentOrganization"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            className="mb-3"
                                            error={!!errors.currentOrganization}
                                            required
                                            helperText={errors?.currentOrganization?.message}
                                            label="Current Organization"
                                            // autoFocus
                                            id="currentOrganization"
                                            variant="outlined"
                                            style={{ width: "100%" }}
                                        />
                                    )}
                                />
                            </div>

                        </div>

                        {/* <div className="d-flex" style={{ gap: '20px' }}>
                            <div className="d-flex w-full align-items-center mb-2 inputFlex">
                                <label className="lightText2">Total Year of Work Experience</label>
                                <span className="px-2">0</span>
                                <label className="lightText2">as on <b>1.4.2024</b></label>
                            </div>
                        </div> */}

                        {/* <div className="flex mb-10">

                            <div style={{ width: "48%" }} className="flex mb-10">

                                <Controller
                                    name="dateOfBirth"
                                    control={control}
                                    render={({ field }) => (
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
                                            <DatePicker
                                                {...field}
                                                className="mb-2 w-full"
                                                error={!!errors.dateOfBirth}
                                                required
                                                slotProps={{
                                                    textField: {
                                                        helperText: errors?.dateOfBirth?.message,
                                                    },
                                                }}
                                                label="Birth Date"
                                                // autoFocus
                                                id="dateOfBirth"
                                                variant="outlined"
                                                style={{ width: "48%" }}
                                            />
                                        </LocalizationProvider>
                                    )}
                                />
                            </div>
                            <div style={{ width: "48%", justifyContent: "flex-start", flexWrap: "inherit" }} className="flex mb-10">
                                <Controller
                                    name="dobProofFile"
                                    control={control}
                                    render={({ field }) => (
                                        <>
                                            <div className="form2-pr text-salary form2-pr" style={{ width: "fit-content" }}> Proof of DOB:</div>
                                            <Button variant="contained" component="label" startIcon={<CloudUploadIcon />} style={{ height: "fit-content" }}>
                                                Upload
                                                <input
                                                    hidden
                                                    type="file"
                                                    onChange={handleFileChangeDob}
                                                />
                                            </Button>
                                            {selectedDobFile && (
                                                <p>Selected File: {selectedDobFile.name}</p>
                                            )}
                                        </>
                                    )}
                                />
                            </div>
                        </div> */}

                        {/* Remove Comment */}
                        <div className="flex mb-10">

                            <Controller
                                name="experince"
                                control={control}
                                render={({ field }) => (
                                    <FormControl
                                        style={{ width: "30%" }}
                                        variant="outlined"
                                        error={!!errors.experince}
                                    >

                                        <InputLabel id="demo-simple-select-label">Select Experience (in years)</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            {...field}
                                            className="mb-2"
                                            label="Select Experience (in years)"
                                            // autoFocus
                                            id="experince"
                                            required
                                        >
                                            <MenuItem value="">Select Experience (in years)</MenuItem>
                                            {
                                                [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].map((i) => (<MenuItem value={`${i} Years`}>{i} Years</MenuItem>))
                                            }
                                        </Select>
                                        <FormHelperText>
                                            {errors?.PositionType?.message}
                                        </FormHelperText>
                                    </FormControl>
                                )}
                            />
                        </div>

                        <div className='text-salary form2-pt-10'>Salary Details (In Numbers Only):</div>
                        <div className="d-flex" style={{ gap: '20px' }}>
                            <div className="d-flex w-full align-items-center mb-2 inputFlex">
                                <Controller
                                    name="grossSalary"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            className="mb-3"
                                            error={!!errors.grossSalary}
                                            type="number"
                                            required
                                            helperText={errors?.grossSalary?.message}
                                            label="Monthly Gross Salary"
                                            // autoFocus
                                            id="grossSalary"
                                            variant="outlined"
                                            style={{ width: "100%" }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="d-flex w-full align-items-center mb-2 inputFlex">
                                <Controller
                                    name="netToHandSalary"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            className="mb-3"
                                            error={!!errors.netToHandSalary}
                                            type="number"
                                            required
                                            helperText={errors?.netToHandSalary?.message}
                                            label="Monthly Take Home Salary"
                                            // autoFocus
                                            id="netToHandSalary"
                                            variant="outlined"
                                            style={{ width: "100%" }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="d-flex w-full align-items-center mb-2 inputFlex justify-content-between">
                                <Controller
                                    name="salarySlipFile"
                                    control={control}
                                    render={({ field }) => (
                                        <>
                                            <label className="lightText2">Latest Salary Slip to be attached</label>
                                            <Button variant="contained" component="label" startIcon={<CloudUploadIcon />} style={{ height: "fit-content" }}>
                                                Upload
                                                <input
                                                    hidden
                                                    type="file"
                                                    onChange={handleFileChangeSalarySlip}
                                                />
                                            </Button>
                                            {selectedSalarySlipFile && (
                                                <p>Selected File: {selectedSalarySlipFile.name}</p>
                                            )}
                                        </>
                                    )}
                                />
                            </div>
                        </div>

                        {/* Remove Comment */}
                        {/* <div className="d-flex" style={{ gap: '20px' }}>
                            <div className="d-flex w-full align-items-center mb-2 inputFlex">
                                <Controller
                                    name="dateOfBirth"
                                    control={control}
                                    render={({ field }) => (
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
                                            <DatePicker
                                                {...field}
                                                className="w-full"
                                                error={!!errors.dateOfBirth}
                                                required
                                                helperText={errors?.dateOfBirth?.message}
                                                label="Date Of Birth"
                                                // autoFocus
                                                id="dateOfBirth"
                                                variant="outlined"
                                            />
                                        </LocalizationProvider>
                                    )}
                                />
                            </div>


                        </div> */}

                        <div className='text-salary form2-pt-10'>Education Details:</div>
                        <TableContainer component={Paper} style={{ marginBottom: "20px" }}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Degree</TableCell>
                                        <TableCell align="center">Completed in Year</TableCell>
                                        <TableCell align="center">Board / University</TableCell>
                                        <TableCell align="center">Subjects / Specialization</TableCell>
                                        <TableCell align="center">Percentage</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center">SSC</TableCell>
                                        <TableCell align="center">
                                            <Controller
                                                name="sscCompletedYear"
                                                control={control}
                                                render={({ field }) => (
                                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
                                                        <DatePicker
                                                            {...field}
                                                            className="w-full"
                                                            error={!!errors.sscCompletedYear}
                                                            required
                                                            helperText={errors?.sscCompletedYear?.message}
                                                            label="Completed Year" views={['year', 'month']}
                                                            // autoFocus
                                                            id="sscCompletedYear"
                                                            variant="outlined"
                                                        />
                                                    </LocalizationProvider>
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Controller
                                                name="sscUniversity"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        className="w-full"
                                                        error={!!errors.sscUniversity}
                                                        required
                                                        helperText={errors?.sscUniversity?.message}
                                                        label="Board / University"
                                                        // autoFocus
                                                        id="sscUniversity"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Controller
                                                name="sscSubjects"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        className="w-full"
                                                        error={!!errors.sscSubjects}
                                                        required
                                                        helperText={errors?.sscSubjects?.message}
                                                        label="Subjects / Specialization"
                                                        // autoFocus
                                                        id="sscSubjects"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Controller
                                                name="sscPercentage"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        className="w-full"
                                                        error={!!errors.sscPercentage}
                                                        required
                                                        type="number"
                                                        helperText={errors?.sscPercentage?.message}
                                                        label="Percentage"
                                                        // autoFocus
                                                        id="sscPercentage"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center">HSC / ITI</TableCell>
                                        <TableCell align="center">
                                            <Controller
                                                name="hscCompletedYear"
                                                control={control}
                                                render={({ field }) => (
                                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
                                                        <DatePicker
                                                            {...field}
                                                            className="w-full"
                                                            error={!!errors.hscCompletedYear}
                                                            required
                                                            helperText={errors?.hscCompletedYear?.message}
                                                            label="Completed Year" views={['year', 'month']}
                                                            // autoFocus
                                                            id="hscCompletedYear"
                                                            variant="outlined"
                                                        />
                                                    </LocalizationProvider>
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Controller
                                                name="hscBoradUniversity"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        className="w-full"
                                                        error={!!errors.hscBoradUniversity}
                                                        required
                                                        helperText={errors?.hscBoradUniversity?.message}
                                                        label="Board / University"
                                                        // autoFocus
                                                        id="hscBoradUniversity"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Controller
                                                name="hscSubjects"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        className="w-full"
                                                        error={!!errors.hscSubjects}
                                                        required
                                                        helperText={errors?.hscSubjects?.message}
                                                        label="Subjects / Specialization"
                                                        // autoFocus
                                                        id="hscSubjects"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Controller
                                                name="hscPercentage"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        className="w-full"
                                                        error={!!errors.hscPercentage}
                                                        required
                                                        type="number"
                                                        helperText={errors?.hscPercentage?.message}
                                                        label="Percentage"
                                                        // autoFocus
                                                        id="hscPercentage"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center">Diploma</TableCell>
                                        <TableCell align="center">
                                            <Controller
                                                name="diplomaCompletedYear"
                                                control={control}
                                                render={({ field }) => (
                                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
                                                        <DatePicker
                                                            {...field}
                                                            className="w-full"
                                                            error={!!errors.diplomaCompletedYear}
                                                            required
                                                            helperText={errors?.diplomaCompletedYear?.message}
                                                            label="Completed Year" views={['year', 'month']}
                                                            // autoFocus
                                                            id="diplomaCompletedYear"
                                                            variant="outlined"
                                                        />
                                                    </LocalizationProvider>
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Controller
                                                name="diplomaBoradUniversity"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        className="w-full"
                                                        error={!!errors.diplomaBoradUniversity}
                                                        required
                                                        helperText={errors?.diplomaBoradUniversity?.message}
                                                        label="Board / University"
                                                        // autoFocus
                                                        id="diplomaBoradUniversity"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Controller
                                                name="diplomaSubjects"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        className="w-full"
                                                        error={!!errors.diplomaSubjects}
                                                        required
                                                        helperText={errors?.diplomaSubjects?.message}
                                                        label="Subjects / Specialization"
                                                        // autoFocus
                                                        id="diplomaSubjects"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Controller
                                                name="diplomaPercentage"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        className="w-full"
                                                        error={!!errors.diplomaPercentage}
                                                        required
                                                        type="number"
                                                        helperText={errors?.diplomaPercentage?.message}
                                                        label="Percentage"
                                                        // autoFocus
                                                        id="diplomaPercentage"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center">Graduation</TableCell>
                                        <TableCell align="center">
                                            <Controller
                                                name="graduationCompletedYear"
                                                control={control}
                                                render={({ field }) => (
                                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
                                                        <DatePicker
                                                            {...field}
                                                            className="w-full"
                                                            error={!!errors.graduationCompletedYear}
                                                            required
                                                            helperText={errors?.graduationCompletedYear?.message}
                                                            label="Completed Year" views={['year', 'month']}
                                                            // autoFocus
                                                            id="graduationCompletedYear"
                                                            variant="outlined"
                                                        />
                                                    </LocalizationProvider>
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Controller
                                                name="graduationBoradUniversity"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        className="w-full"
                                                        error={!!errors.graduationBoradUniversity}
                                                        required
                                                        helperText={errors?.graduationBoradUniversity?.message}
                                                        label="Board / University"
                                                        // autoFocus
                                                        id="graduationBoradUniversity"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Controller
                                                name="graduationSubjects"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        className="w-full"
                                                        error={!!errors.graduationSubjects}
                                                        required
                                                        helperText={errors?.graduationSubjects?.message}
                                                        label="Subjects / Specialization"
                                                        // autoFocus
                                                        id="graduationSubjects"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Controller
                                                name="graduationPercentage"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        className="w-full"
                                                        error={!!errors.graduationPercentage}
                                                        required
                                                        type="number"
                                                        helperText={errors?.graduationPercentage?.message}
                                                        label="Percentage"
                                                        // autoFocus
                                                        id="graduationPercentage"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center">Post Graduation</TableCell>
                                        <TableCell align="center">
                                            <Controller
                                                name="postGraduationCompletedYear"
                                                control={control}
                                                render={({ field }) => (
                                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
                                                        <DatePicker
                                                            {...field}
                                                            className="w-full"
                                                            error={!!errors.postGraduationCompletedYear}
                                                            required
                                                            helperText={errors?.postGraduationCompletedYear?.message}
                                                            label="Completed Year" views={['year', 'month']}
                                                            // autoFocus
                                                            id="postGraduationCompletedYear"
                                                            variant="outlined"
                                                        />
                                                    </LocalizationProvider>
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Controller
                                                name="postGraduationBoradUniversity"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        className="w-full"
                                                        error={!!errors.postGraduationBoradUniversity}
                                                        required
                                                        helperText={errors?.postGraduationBoradUniversity?.message}
                                                        label="Board / University"
                                                        // autoFocus
                                                        id="postGraduationBoradUniversity"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Controller
                                                name="postGraduationSubjects"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        className="w-full"
                                                        error={!!errors.postGraduationSubjects}
                                                        required
                                                        helperText={errors?.postGraduationSubjects?.message}
                                                        label="Subjects / Specialization"
                                                        // autoFocus
                                                        id="postGraduationSubjects"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Controller
                                                name="postGraduationPercentage"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        className="w-full"
                                                        error={!!errors.postGraduationPercentage}
                                                        required
                                                        type="number"
                                                        helperText={errors?.postGraduationPercentage?.message}
                                                        label="Percentage"
                                                        // autoFocus
                                                        id="postGraduationPercentage"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center">PG Diploma</TableCell>
                                        <TableCell align="center">
                                            <Controller
                                                name="pgDiplomaCompletedYear"
                                                control={control}
                                                render={({ field }) => (
                                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
                                                        <DatePicker
                                                            {...field}
                                                            className="w-full"
                                                            error={!!errors.pgDiplomaCompletedYear}
                                                            required
                                                            helperText={errors?.pgDiplomaCompletedYear?.message}
                                                            label="Completed Year" views={['year', 'month']}
                                                            // autoFocus
                                                            id="pgDiplomaCompletedYear"
                                                            variant="outlined"
                                                        />
                                                    </LocalizationProvider>
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Controller
                                                name="pgDiplomaBoradUniversity"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        className="w-full"
                                                        error={!!errors.pgDiplomaBoradUniversity}
                                                        required
                                                        helperText={errors?.pgDiplomaBoradUniversity?.message}
                                                        label="Board / University"
                                                        // autoFocus
                                                        id="pgDiplomaBoradUniversity"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Controller
                                                name="pgDiplomaSubjects"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        className="w-full"
                                                        error={!!errors.pgDiplomaSubjects}
                                                        required
                                                        helperText={errors?.pgDiplomaSubjects?.message}
                                                        label="Subjects / Specialization"
                                                        // autoFocus
                                                        id="pgDiplomaSubjects"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Controller
                                                name="pgDiplomaPercentage"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        className="w-full"
                                                        error={!!errors.pgDiplomaPercentage}
                                                        required
                                                        type="number"
                                                        helperText={errors?.pgDiplomaPercentage?.message}
                                                        label="Percentage"
                                                        // autoFocus
                                                        id="pgDiplomaPercentage"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center">MBA</TableCell>
                                        <TableCell align="center">
                                            <Controller
                                                name="mbaCompletedYear"
                                                control={control}
                                                render={({ field }) => (
                                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
                                                        <DatePicker
                                                            {...field}
                                                            className="w-full"
                                                            error={!!errors.mbaCompletedYear}
                                                            required
                                                            helperText={errors?.mbaCompletedYear?.message}
                                                            label="Completed Year" views={['year', 'month']}
                                                            // autoFocus
                                                            id="mbaCompletedYear"
                                                            variant="outlined"
                                                        />
                                                    </LocalizationProvider>
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Controller
                                                name="mbaBoradUniversity"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        className="w-full"
                                                        error={!!errors.mbaBoradUniversity}
                                                        required
                                                        helperText={errors?.mbaBoradUniversity?.message}
                                                        label="Board / University"
                                                        // autoFocus
                                                        id="mbaBoradUniversity"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Controller
                                                name="mbaSubjects"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        className="w-full"
                                                        error={!!errors.mbaSubjects}
                                                        required
                                                        helperText={errors?.mbaSubjects?.message}
                                                        label="Subjects / Specialization"
                                                        // autoFocus
                                                        id="mbaSubjects"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Controller
                                                name="mbaPercentage"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        className="w-full"
                                                        error={!!errors.mbaPercentage}
                                                        required
                                                        type="number"
                                                        helperText={errors?.mbaPercentage?.message}
                                                        label="Percentage"
                                                        // autoFocus
                                                        id="mbaPercentage"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center">Doctoral</TableCell>
                                        <TableCell align="center">
                                            <Controller
                                                name="doctoralCompletedYear"
                                                control={control}
                                                render={({ field }) => (
                                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
                                                        <DatePicker
                                                            {...field}
                                                            className="w-full"
                                                            error={!!errors.doctoralCompletedYear}
                                                            required
                                                            helperText={errors?.doctoralCompletedYear?.message}
                                                            label="Completed Year" views={['year', 'month']}
                                                            // autoFocus
                                                            id="doctoralCompletedYear"
                                                            variant="outlined"
                                                        />
                                                    </LocalizationProvider>
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Controller
                                                name="doctoralBoradUniversity"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        className="w-full"
                                                        error={!!errors.doctoralBoradUniversity}
                                                        required
                                                        helperText={errors?.doctoralBoradUniversity?.message}
                                                        label="Board / University"
                                                        // autoFocus
                                                        id="doctoralBoradUniversity"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Controller
                                                name="doctoralSubjects"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        className="w-full"
                                                        error={!!errors.doctoralSubjects}
                                                        required
                                                        helperText={errors?.doctoralSubjects?.message}
                                                        label="Subjects / Specialization"
                                                        // autoFocus
                                                        id="doctoralSubjects"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Controller
                                                name="doctoralPercentage"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        className="w-full"
                                                        error={!!errors.doctoralPercentage}
                                                        required
                                                        type="number"
                                                        helperText={errors?.doctoralPercentage?.message}
                                                        label="Percentage"
                                                        // autoFocus
                                                        id="doctoralPercentage"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center">Other</TableCell>
                                        <TableCell align="center">
                                            <Controller
                                                name="OtherCompletedYear"
                                                control={control}
                                                render={({ field }) => (
                                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
                                                        <DatePicker
                                                            {...field}
                                                            className="w-full"
                                                            error={!!errors.OtherCompletedYear}
                                                            required
                                                            helperText={errors?.OtherCompletedYear?.message}
                                                            label="Completed Year" views={['year', 'month']}
                                                            // autoFocus
                                                            id="OtherCompletedYear"
                                                            variant="outlined"
                                                        />
                                                    </LocalizationProvider>
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Controller
                                                name="otherBoardUniversity"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        className="w-full"
                                                        error={!!errors.otherBoardUniversity}
                                                        required
                                                        helperText={errors?.otherBoardUniversity?.message}
                                                        label="Board / University"
                                                        // autoFocus
                                                        id="otherBoardUniversity"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Controller
                                                name="otherSubjects"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        className="w-full"
                                                        error={!!errors.otherSubjects}
                                                        required
                                                        helperText={errors?.otherSubjects?.message}
                                                        label="Subjects / Specialization"
                                                        // autoFocus
                                                        id="otherSubjects"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Controller
                                                name="otherPercentage"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        className="w-full"
                                                        error={!!errors.otherPercentage}
                                                        required
                                                        type="number"
                                                        helperText={errors?.otherPercentage?.message}
                                                        label="Percentage"
                                                        // autoFocus
                                                        id="otherPercentage"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <div className='text-salary form2-pt-10'>Work Details: Fresher<span className="px-1"></span>
                            <label className='toggle-container' onClick={handleToggleChange}>
                                <div className={`toggle-btn ${!toggle ? "disable" : ""}`}>
                                    {toggle ? "" : ""}
                                </div>
                            </label>
                        </div>




                        <div>
                <div className="mt-0 ml-10 mb-40">

                  <Grid container spacing={1}>
                    <Grid item xs={3}>
                            <TextField
                              autoFocus
                              label="Name of the Institute/Organization"
                              variant="outlined"
                              fullWidth
                              value={input1}
                              onChange={(e) => {
                                setInput1(e.target.value);
                                if (e.target.value != "") {
                                  setInput1Err("");
                                } else {
                                  setInput1Err("This is required !!!");
                                }
                              }}
                            />
                            <span className="text-red mt-10 font-semibold">
                              {input1Err}
                            </span>
                    </Grid>
                    <Grid item xs={3}>
                    
                            <TextField
                              autoFocus
                              label="Designation"
                              variant="outlined"
                              fullWidth
                              value={input2}
                              onChange={(e) => {
                                setInput2(e.target.value);
                                if (e.target.value != "") {
                                  setInput2Err("");
                                } else {
                                  setInput2Err("This is required !!!");
                                }
                              }}
                            />
                            <span className="text-red mt-10 font-semibold">
                              {input2Err}
                            </span>
                    </Grid>
                    <Grid item xs={3}>
                      <div className="mt-0 mb-0">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer
                            components={["DatePicker"]}
                            className=" w-full"
                          >
                            <DatePicker
                              label="Date of Joining"
                              format="DD-MM-YYYY"
                              className=" w-full"
                              value={startdate}
                              onChange={(newValue) => {
                                setstartdate(newValue);
                              }}
                              shouldDisableDate={(date) => {
                                return disabledates.includes(
                                  date.format("YYYY-MM-DD")
                                );
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                        <FormHelperText>
                          {startdate == "" ? "Please Enter StartDate" : ""}
                        </FormHelperText>
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <div className="mt-0 mb-0">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer
                            components={["DatePicker"]}
                            className=" w-full"
                          >
                            <DatePicker
                              label="Date of Relieving"
                              format="DD-MM-YYYY"
                              className=" w-full"
                              value={enddate}
                              onChange={(newValue) => {
                                setenddate(newValue);
                              }}
                              shouldDisableDate={(date) => {
                                return disabledates.includes(
                                  date.format("YYYY-MM-DD")
                                );
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                        <FormHelperText>
                          {enddate == "" ? "Please Enter EndDate" : ""}
                        </FormHelperText>
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                            <TextField
                              label="Total Length of Service"
                              variant="outlined"
                              fullWidth
                              value={input3}
                              type="number"
                              maxLength={"10"}
                              inputProps={{ maxLength: 10 }}
                              onChange={(e) => {
                                setInput3(e.target.value.slice(0, 10));
                                if (e.target.value == "") {
                                  setInput3Err("This is required !!!");
                                } else {
                                  setInput3Err("");
                                }
                              }}
                              //onChange={handleText2}
                            />
                            <span className="text-red mt-10 font-semibold">
                              {input3Err}
                            </span>
                    </Grid>
                    <Grid item xs={3}>
                            <TextField
                              label="Salary Per Month"
                              variant="outlined"
                              fullWidth
                              value={input4}
                              type="number"
                              maxLength={"10"}
                              inputProps={{ maxLength: 10 }}
                              onChange={(e) => {
                                setInput4(e.target.value.slice(0, 10));
                                if (e.target.value == "") {
                                  setInput4Err("This is required !!!");
                                } else {
                                  setInput4Err("");
                                }
                              }}
                              //onChange={handleText2}
                            />
                            <span className="text-red mt-10 font-semibold">
                              {input4Err}
                            </span>
                    </Grid>
                    <Grid item xs={3}>
                    
                    <TextField
                      autoFocus
                      label="Main Job Profile"
                      variant="outlined"
                      fullWidth
                      value={input5}
                      onChange={(e) => {
                        setInput5(e.target.value);
                        if (e.target.value != "") {
                          setInput5Err("");
                        } else {
                          setInput5Err("This is required !!!");
                        }
                      }}
                    />
                    <span className="text-red mt-10 font-semibold">
                      {input5Err}
                    </span>
                    </Grid>
                    <Grid item xs={3}>
                    
                          <FormControl className=' w-full'>
                      <InputLabel id="demo-simple-select-label">Work Experience Certificate Available</InputLabel>
                      <Select value={input6} labelId='demo-simple-select-label' label='Work Experience Certificate Available' 
                       onChange={(e) => {setInput6(e.target.value)
                        if(e.target.value != ''){
                            setInput6Err('')
                        }
                        else{
                            setInput6Err('This is required !!!')
                        }
                        }
                      }>
                     
 
                <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                      </Select>
                    
                    </FormControl>
                    </Grid>
                    <Grid item xs={1}>
                      <div className="mt-20 mb-30">
                        <Fab
                          color="primary"
                          size="medium"
                          aria-label="add"
                          className="mt-20 mb-30"
                          onClick={handleClickAddBank}
                        >
                          <AddIcon />
                        </Fab>
                      </div>
                    </Grid>
                  </Grid>
                </div>

                {/* {DateRangedata && DateRangedata.map((bank, index) => {
                  return (
                    <div className="mt-10 ml-10 mb-10" key={index}>
                      <Grid container spacing={1}>


                        <Grid item xs={3}>
                        <TextField
                                  // label={`Name-${counter}`}
                                  variant="filled"
                                  fullWidth
                                  disabled
                                  value={bank.input1}
                                />
                        </Grid>
                        <Grid item xs={3}>
                        
                        <TextField
                                  // label={`Name-${counter}`}
                                  variant="filled"
                                  fullWidth
                                  disabled
                                  value={bank.input2}
                                />
                        </Grid>
                        <Grid item xs={3}>
                          <div className="mt-10 mb-10">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer
                                components={["DatePicker"]}
                                className=" w-full"
                              >
                                <DatePicker
                                  disabled
                                  label="Start Date"
                                  format="DD-MM-YYYY"
                                  className=" w-full"
                                  value={moment(bank.startdate)}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <div className="mt-10 mb-10">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer
                                components={["DatePicker"]}
                                className=" w-full"
                              >
                                <DatePicker
                                  disabled
                                  label="End Date"
                                  format="DD-MM-YYYY"
                                  className=" w-full"
                                  value={moment(bank.enddate)}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                        <TextField
                                  // label={`Name-${counter}`}
                                  variant="filled"
                                  fullWidth
                                  disabled
                                  value={bank.input3}
                                />
                        </Grid>
                        <Grid item xs={3}>
                        <TextField
                                  // label={`Name-${counter}`}
                                  variant="filled"
                                  fullWidth
                                  disabled
                                  value={bank.input4}
                                />
                        </Grid>
                        <Grid item xs={3}>
                        <TextField
                                  // label={`Name-${counter}`}
                                  variant="filled"
                                  fullWidth
                                  disabled
                                  value={bank.input5}
                                />
                        </Grid>
                        <Grid item xs={3}>
                        <TextField
                                  // label={`Name-${counter}`}
                                  variant="filled"
                                  fullWidth
                                  disabled
                                  value={bank.input6}
                                />
                        </Grid>
                        
                        
                        
                        <Grid item xs={1}>
                          <Fab
                            sx={{ backgroundColor: "red" }}
                            size="medium"
                            aria-label="remove"
                            className="mt-20 mb-10"
                            onClick={() => handleClickRemoveBank(index)}
                          >
                            <Remove />
                          </Fab>
                        </Grid>
                      </Grid>
                    </div>
                  );
                })} */}
              </div>











                        <TableContainer className="mb-3 mt-20" component={Paper} style={toggle ? { display: "none" } : { display: "block" }}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Name of the Institute/Organization</TableCell>
                                        <TableCell align="center">Designation</TableCell>
                                        <TableCell align="center">Date of Joining</TableCell>
                                        <TableCell align="center">Date of Relieving</TableCell>
                                        <TableCell align="center">Total Length of Service</TableCell>
                                        <TableCell align="center">Salary Per Month</TableCell>
                                        <TableCell align="center">Main Job Profile</TableCell>
                                        <TableCell align="center">Work Experience Certificate Available</TableCell>
                                        <TableCell align="center"><button className="addButton" type="button" onClick={handleAdd}><AddIcon /></button></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {fields.map((field, index) => (
                                        <TableRow>
                                            <TableCell align="center">
                                                <Controller
                                                    name="firstOrganizationName"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            className="w-full"
                                                            error={!!errors.firstOrganizationName}
                                                            required
                                                            helperText={errors?.firstOrganizationName?.message}
                                                            label="Institute/Organization"
                                                            // autoFocus
                                                            id="firstOrganizationName"
                                                            variant="outlined"
                                                            value={field.nameOfOrg}
                                                            onChange={(event) => handleChange(index, event)}
                                                        />
                                                    )}
                                                />
                                            </TableCell>
                                            <TableCell align="center">
                                                <Controller
                                                    name="firstDesignation"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            className="w-full"
                                                            error={!!errors.firstDesignation}
                                                            required
                                                            helperText={errors?.firstDesignation?.message}
                                                            label="Designation"
                                                            // autoFocus
                                                            id="firstDesignation"
                                                            variant="outlined"
                                                            value={field.designation}
                                                            onChange={(event) => handleChange(index, event)}
                                                        />
                                                    )}
                                                />
                                            </TableCell>
                                            <TableCell align="center">
                                                <Controller
                                                    name="firstDateOfJoining"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
                                                            <DatePicker
                                                                {...field}
                                                                className="w-full"
                                                                error={!!errors.firstDateOfJoining}
                                                                required
                                                                helperText={errors?.firstDateOfJoining?.message}
                                                                label="Date Of Joining" views={['year', 'month']}
                                                                // autoFocus
                                                                id="firstDateOfJoining"
                                                                variant="outlined"
                                                                value={field.joiningDate}
                                                                onChange={(event) => handleChange(index, event)}
                                                            />
                                                        </LocalizationProvider>
                                                    )}
                                                />
                                            </TableCell>
                                            <TableCell align="center">
                                                <Controller
                                                    name="firstDateOfRelieving"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
                                                            <DatePicker
                                                                {...field}
                                                                className="w-full"
                                                                error={!!errors.firstDateOfRelieving}
                                                                required
                                                                helperText={errors?.firstDateOfRelieving?.message}
                                                                label="Date Of Relieving" views={['year', 'month']}
                                                                // autoFocus
                                                                id="firstDateOfRelieving"
                                                                variant="outlined"
                                                                value={field.relievingDate}
                                                                onChange={(event) => handleChange(index, event)}
                                                            />
                                                        </LocalizationProvider>
                                                    )}
                                                />
                                            </TableCell>
                                            <TableCell align="center">
                                                <Controller
                                                    name="firstServiceLength"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            className="w-full"
                                                            error={!!errors.firstServiceLength}
                                                            required
                                                            type="number"
                                                            helperText={errors?.firstServiceLength?.message}
                                                            label="Service Length"
                                                            // autoFocus
                                                            id="firstServiceLength"
                                                            variant="outlined"
                                                            value={field.serviceLength}
                                                            onChange={(event) => handleChange(index, event)}
                                                        />
                                                    )}
                                                />
                                            </TableCell>
                                            <TableCell align="center">
                                                <Controller
                                                    name="firstSalaryPerMonth"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            className="w-full"
                                                            error={!!errors.firstSalaryPerMonth}
                                                            required
                                                            type="number"
                                                            helperText={errors?.firstSalaryPerMonth?.message}
                                                            label="Salary Per Month"
                                                            // autoFocus
                                                            id="firstSalaryPerMonth"
                                                            variant="outlined"
                                                            value={field.monthlySalary}
                                                            onChange={(event) => handleChange(index, event)}
                                                        />
                                                    )}
                                                />
                                            </TableCell>
                                            <TableCell align="center">
                                                <Controller
                                                    name="firstMainJobProfile"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            className="w-full"
                                                            error={!!errors.firstMainJobProfile}
                                                            required
                                                            helperText={errors?.firstMainJobProfile?.message}
                                                            label="Job Profile"
                                                            // autoFocus
                                                            id="firstMainJobProfile"
                                                            variant="outlined"
                                                            value={field.jobProfile}
                                                            onChange={(event) => handleChange(index, event)}
                                                        />
                                                    )}
                                                />
                                            </TableCell>
                                            <TableCell align="center">
                                                <Controller
                                                    name="firstWorkExpCerti"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <FormControl
                                                            variant="outlined"
                                                            error={!!errors.firstWorkExpCerti}

                                                            style={{ width: "100%" }}
                                                        >

                                                            <InputLabel id="demo-simple-select-label">Certificate Available</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                {...field}
                                                                label="Certificate Available"
                                                                // autoFocus
                                                                id="firstWorkExpCerti"
                                                                required
                                                                value={field.experinceCertificate}
                                                                onChange={(event) => handleChange(index, event)}
                                                            >
                                                                {/* <MenuItem value="">Certificate Available</MenuItem> */}
                                                                {
                                                                    [
                                                                        {
                                                                            value: true,
                                                                            label: 'Yes',
                                                                        },
                                                                        {
                                                                            value: false,
                                                                            label: 'No',
                                                                        }
                                                                    ].map((i) => (<MenuItem value={i.value}>{i.label}</MenuItem>))
                                                                }
                                                            </Select>
                                                            <FormHelperText>
                                                                {errors?.PositionType?.message}
                                                            </FormHelperText>
                                                        </FormControl>
                                                    )}
                                                />
                                            </TableCell>
                                            <TableCell align="center">
                                                <button className="removeButton" type="button" onClick={() => handleRemove(index)}>
                                                    <DeleteIcon />
                                                </button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <div className="flex mb-3" style={{ justifyContent: "flex-start", alignItems: 'center' }}>
                            <label className="lightText2">Minimum Notice Period Required to Join in case of selection:</label>
                            <div style={{ width: "2%" }} />
                            <Controller
                                name="minimumnoticePeriod"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        className="mb-2 w-full"
                                        error={!!errors.minimumnoticePeriod}
                                        required
                                        type="number"
                                        helperText={errors?.minimumnoticePeriod?.message}
                                        label="Days"
                                        // autoFocus
                                        id="minimumnoticePeriod"
                                        variant="outlined"
                                        style={{ width: "10%" }}
                                    />
                                )}
                            />
                        </div>
                        <div className="flex mb-3" style={{ justifyContent: "flex-start", alignItems: 'center' }}>
                            <label className="lightText2">Are you ready to relocate to any location in India?</label>
                            <div style={{ width: "2%" }} />
                            <Controller
                                name="readyToRelocate"
                                control={control}
                                render={({ field }) => (
                                    <RadioGroup
                                        {...field}
                                        id="readyToRelocate"
                                        row
                                        name="readyToRelocate"
                                        className="mb-3"
                                    >
                                        <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                        <FormControlLabel value={false} control={<Radio />} label="No" />
                                    </RadioGroup>
                                )}
                            />
                        </div>
                        <div className="flex mb-3" style={{ justifyContent: "flex-start", alignItems: 'center' }}>
                            <label className="lightText2">Upload Resume / CV * (UPDATED CV - Word or PDF file only):</label>
                            <div style={{ width: "2%" }} />
                            <Controller
                                name="cvFile"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <Button variant="contained" component="label" startIcon={<CloudUploadIcon />} style={{ height: "fit-content" }}>
                                            Upload
                                            <input
                                                hidden
                                                type="file"
                                                onChange={handleFileChangeCV}
                                            />
                                        </Button>
                                        {selectedCVFile && (
                                            <p>Selected File: {selectedCVFile.name}</p>
                                        )}
                                    </>
                                )}
                            />
                        </div>
                        <div className="flex align-center mb-3" style={{ justifyContent: "flex-start" }}>
                            <Controller
                                name="clerification"
                                control={control}
                                render={({ field }) => (
                                    <FormGroup {...field} style={{ marginLeft: "10px", width: "fit-content" }}>
                                        <FormControlLabel required control={<Checkbox id="clerification" checked={clerification} onChange={(value) => {
                                            setClerification(value.target.checked);
                                        }} />} />
                                    </FormGroup>
                                )}
                            />
                            <div className=' form2-pr form2-declare-styling'>I hereby certify that the above information are true and correct to the best of my knowledge and in case of any discrepancy, the application will be disqualified. </div>
                        </div>
                        <div className='flex mb-10' style={{ justifyContent: "flex-start", marginBottom: "20px", marginTop: "20px", gap: "20px" }}>

                            <Button
                                variant="contained"
                                sx={buttonSx}
                                disabled={loadingState || !clerification}
                                onClick={handleSubmit(onSubmit)}
                            >
                                Submit
                            </Button>
                            {loadingState && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        color: blue[500],
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                />
                            )}
                            {/* {loadingState ? <RingLoader color="#7cba27" /> : <Button variant="contained" disabled={!clerification} component="label" endIcon={<IosShareIcon />} style={{ marginLeft: "10px" }}>
                                Submit
                                <input
                                    hidden
                                    type="submit"
                                    onClick={handleSubmit(onSubmit)}
                                // onChange={handleFileChange}
                                />
                            </Button>}
                            <div style={{ width: "2%" }} /> */}
                            {/* <Button variant="contained" component="label" endIcon={<RestartAltIcon />}>
                                Reset
                                <input
                                    hidden
                                    type="reset"
                                // onChange={handleFileChange}
                                />
                            </Button> */}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddApplicationForm;
// http://43.204.72.146/