import React from 'react'
import BreadCrumbs from '../../BreadCrumbs/BreadCrumbs'
import './Sitemap.css'
import BreadCrumbImage from '../../../Images_1/CommonSection/Braedcrumbs/publications.jpg'
import { Link } from 'react-router-dom'

function Sitemap() {
    return (
        <div>
            <BreadCrumbs parent="Home" child="GERMI's Sitemap" parentPath="/" imgPath={BreadCrumbImage} />
            <section className='sitemap-section'>
                <div className='sitemap-main-div'>
                    <div className='sitemap-div'>
                        <h4 className='parent-name'><Link to='/'>Home</Link></h4>
                    </div>
                    <div className='sitemap-div'>
                        <h4 className='parent-name'>About Us</h4>
                        <ul className='child-list'>
                            <li><Link to='/aboutus'>About GERMI</Link></li>
                            <li><Link to='/ourmanagementteams'>Management Team</Link></li>
                            <li><Link to='/dgdesk'>DG's Desk</Link></li>
                            <li><Link to='/technicalteams'>Technical Team</Link></li>
                            <li><Link to='/ourhradminteams'>HR, Admin & IT Team</Link></li>
                            <li><Link to='/ourAccountsteams'>Finance Team</Link></li>
                        </ul>
                    </div>
                   
                    <div className='sitemap-div'>
                        <h4 className='parent-name'>Our Service</h4>
                        <ul className='child-list'>
                            <li><Link to='/ourservices/1'>Advisory & Consulting</Link></li>
                            <li><Link to='/ourservices/2'>Research & Development</Link></li>
                            <li><Link to='/ourservices/3'>Training & Skill Development</Link></li>
                        </ul>
                    </div>

                    <div className='sitemap-div'>
                        <h4 className='parent-name'>Department</h4>
                        <ul className='child-list'>
                            <li><Link to='/departments/new-technology-management/5/0'>New Technology Management</Link></li>
                            <li><Link to='/departments/renewable-management/5/0'>Renewables Management</Link></li>
                            <li><Link to='/departments/petroleum-management/5/0'>Petroleum Management</Link></li>
                            <li><Link to='/departments/petroleum-research-management/5/0'>Petroleum Research Management</Link></li>
                            <li><Link to='/departments/environment-management/5/0'>Environment Management</Link></li>
                       
                        </ul>
                    </div>
                    <div className='sitemap-div'>
                        <h4 className='parent-name'><Link to='/successstories/0/1'>Success Stories</Link></h4>
                        <ul className='child-list'>
                            <li><Link to='/successstories/101/1/'>New Technology Management</Link></li>
                            <li><Link to='/successstories/101/2/'>Renewables Management</Link></li>
                            <li><Link to='/successstories/101/3/'>Petroleum Management</Link></li>
                            <li><Link to='/successstories/101/5/'>Petroleum Research Management</Link></li>
                            <li><Link to='/successstories/101/4/'>Environment Management</Link></li>
                       
                        </ul>
                    </div>

                    <div className='sitemap-div'>
                        <h4 className='parent-name'>Notifications</h4>
                        <ul className='child-list'>
                            <li><Link to='/announcementlist'>Announcements</Link></li>
                            <li><Link to='/tenderlist'>Tenders</Link></li>
                            <li><Link to='/newslist'>News</Link></li>
                        </ul>
                    </div>
                    <div className='sitemap-div'>
                        <h4 className='parent-name'>Resources</h4>
                        <ul className='child-list'>
                            <li><Link to='/publications'>Our Publications</Link></li>
                            <li><Link to='/GermiPatents'>Our Patents</Link></li>
                            <li><Link to='/projectreport'>Project Report</Link></li>
                            <li><Link to='/fcra'>FCRA</Link></li>
                            <li><Link to='/solarcalculator'>Solar Calculator</Link></li>
                            <li><Link to='/evcalculator'>EV Calculator</Link></li>
                        </ul>
                    </div>
                    <div className='sitemap-div'>
                        <h4 className='parent-name'><Link to='/careers'>Careers</Link></h4>
                    </div>
                    <div className='sitemap-div'>
                        <h4 className='parent-name'><Link to='/give'>Donate</Link></h4>
                    </div>
                    <div className='sitemap-div'>
                        <h4 className='parent-name'><Link to='/contactus'>Contact Us</Link></h4>
                    </div>
                </div >
            </section >
        </div >
    )
}

export default Sitemap
