import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"; 
import { publicRequest } from "../../../constants"; 

export const PopupData = createAsyncThunk('popupData', async() => {
  const response = await publicRequest.get('/popupdatalist');
  // console.log(response + "helo");
  return response.data;
});

const PopupDataSlice = createSlice({
  name: 'popupData',
  initialState: {},
  extraReducers: (builder) => {
    builder.addCase(PopupData.fulfilled, (state, action)=> {
      state.data = action.payload
    })
  }
});

export default PopupDataSlice.reducer;